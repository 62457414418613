
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/img-1.jpg'
import strory2 from '../../images/events/img-2.jpg'
import strory3 from '../../images/events/img-3.jpg'

import './style.css'

const Location = (props) => {

    const {
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div id="event" className="event-section section-padding">
            <Sectiontitle section={'Cerimônia e Recepção'} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src="https://marryme-now.s3.amazonaws.com/191d5483-ff9a-4895-bc4c-f2e2fd9ce13a.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Cerimônia</h3>
                                                    <span>Domingo, 27 Outubro de 2024, às 15:00 horas</span>
                                                    <span>Casa Montenegro</span>
                                                    <p>Neste lugar, iremos nos 
                                                        unir perante Deus, nossos familiares, e amigos queridos, em amor e compromisso eterno. Que nossas vidas juntas sejam abençoadas com a graça divina, 
                                                        guiadas pela luz da fé e fortalecidas pelo amor celestial.
                                                         Que nossa união seja um testemunho vivo do poder do amor de Deus em nossas vidas. </p>
                                                    <Button className="btn" onClick={toggle}>Localização</Button>
                                                    <Modal isOpen={modal} toggle={toggle} className={className}>
                                                        <ModalHeader toggle={toggle}>Location</ModalHeader>
                                                        <ModalBody>
                                                            <div className="location-map">
                                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1990.4687200511607!2d-38.549975873688105!3d-3.823575475317593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74f653a2a01bd%3A0x9c2736099ce766ec!2sCasa%20Montenegro!5e0!3m2!1spt-BR!2sbr!4v1712869420962!5m2!1spt-BR!2sbr"  />
                                                            </div>
                                                        </ModalBody>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src="https://marryme-now.s3.amazonaws.com/24107588-b567-45b5-a2dc-24cba6e634ea.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Recepção</h3>
                                                    <span>Domingo, 27 Outubro de 2024, às 16:30 horas</span>
                                                    <span>Casa Montenegro</span>
                                                   <p> Que esta festa seja repleta de risos, danças e momentos inesquecíveis. 
                                                    Que cada batida da música e cada risada compartilhada sejam lembranças eternas deste dia especial. 
                                                    Vamos celebrar 
                                                    juntos esta união e brindar ao amor! 🥂</p>
                                                    <Button className="btn" onClick={toggle}>Localização</Button>
                                                    <Modal isOpen={modal} toggle={toggle} className={className}>
                                                        <ModalHeader toggle={toggle}>Location</ModalHeader>
                                                        <ModalBody>
                                                            <div className="location-map">
                                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1990.4687200511607!2d-38.549975873688105!3d-3.823575475317593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74f653a2a01bd%3A0x9c2736099ce766ec!2sCasa%20Montenegro!5e0!3m2!1spt-BR!2sbr!4v1712869420962!5m2!1spt-BR!2sbr"  />
                                                            </div>
                                                        </ModalBody>
                                                    </Modal>                                                                                           
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;