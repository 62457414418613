import React, { Component } from 'react';
import Sectiontitle from '../section-title';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './style.css';
import axios from 'axios';

class Rsvp extends Component {
    state = {
        name: '',
        email: '',
        rsvp: '',
        events: '',
        notes: '',
        error: {},
        showModal: false
    };

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error
        });
    };

    submitHandler = async (e) => {
        e.preventDefault();

        const { name, email, rsvp, events, notes, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (rsvp === '') {
            error.rsvp = "Select your number of rsvp";
        }
        if (events === '') {
            error.events = "Select your event list";
        }
        if (notes === '') {
            error.notes = "Please enter your note";
        }

        if (error) {
            this.setState({
                error
            });
        }

        if (error.name === '' && error.email === '' && error.rsvp === '' && error.notes === '') {
            try {
                await this.sendDataToAPI({ name, email, rsvp, notes });
                this.setState({
                    name: '',
                    email: '',
                    rsvp: '',
                    events: '',
                    notes: '',
                    error: {},
                    showModal: true
                });
            } catch (error) {
                console.error('Failed to submit the form:', error);
            }
        }

        console.log(this.state);
        console.log(this.state.error.notes);
    };

    sendDataToAPI = async (data) => {
        try {
            const response = await axios.post('https://api.listaperfeita.com/guest-responses', {
                name: data.name,
                email: data.email,
                number_of_guests: data.rsvp,
                message_to_couple: data.notes
            });
            console.log('API Response:', response.data);
            return response;
        } catch (error) {
            console.error('Failed to send data to API:', error);
            throw error;
        }
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { name, email, rsvp, notes, error, showModal } = this.state;

        return (
            <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                <Sectiontitle section={'Confirme sua Presença'} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="rsvp-wrap">
                                <form onSubmit={this.submitHandler}>
                                    <div className="contact-form form-style">
                                        <div className="col-12 col-sm-12">
                                            <input
                                                type="text"
                                                value={name}
                                                onChange={this.changeHandler}
                                                placeholder="Seu Nome*"
                                                id="fname"
                                                name="name"
                                            />
                                            <p>{error.name ? error.name : ''}</p>
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <input
                                                type="text"
                                                placeholder="Seu E-mail*"
                                                onChange={this.changeHandler}
                                                value={email}
                                                id="email"
                                                name="email"
                                            />
                                            <p>{error.email ? error.email : ''}</p>
                                        </div>
                                        <div className="col col-sm-12">
                                            <select
                                                className="form-control"
                                                onChange={this.changeHandler}
                                                value={rsvp}
                                                name="rsvp"
                                            >
                                                <option disabled value="">
                                                    Número de Convidados*
                                                </option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <p>{error.rsvp ? error.rsvp : ''}</p>
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <textarea
                                                className="contact-textarea"
                                                value={notes}
                                                onChange={this.changeHandler}
                                                placeholder="Escreva uma mensagem aos noivos"
                                                name="notes"
                                            ></textarea>
                                            <p>{error.notes ? error.notes : ''}</p>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button id="submit" type="submit" className="submit">
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={showModal} toggle={this.closeModal}>
                    <ModalHeader toggle={this.closeModal}>Confirmação</ModalHeader>
                    <ModalBody>
                        <p>Sua presença foi confirmada!</p>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default Rsvp;
