import React from 'react'
import Sectiontitle from '../section-title'
import './style.css'

const Couple = (props) => {
    return(
        <div id="couple" className={`about-wrap ${props.couple}`}>
            <div className="couple-area section-padding">
                <Sectiontitle section={'Casal Feliz'}/>
                <div className="container">
                    <div className="couple-wrap">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 couple-single">
                                <div className="couple-wrap couple-wrap-2">
                                    <div className="couple-img">
                                        <img src={"https://marryme-now.s3.amazonaws.com/8aa9716d-5d80-4b6a-bb08-c9016679b062.jpg"} style={{objectFit: 'cover'}} alt="thumb"/>
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Luiza Oliveira</h3>
                                            <p style={{ lineHeight: '0.5' }}>26 anos</p>
                                            <p style={{ lineHeight: '0.5' }}>Profissão: Analista administrativa</p> 
                                            <p style={{ lineHeight: '0.5' }}>Gênero musical favorito: MPB e POP</p>
                                            <p style={{ lineHeight: '0.5' }}>Hobby: Leitura</p>
                                            <p style={{ lineHeight: '0.5' }}>Ama: Viajar e montar roteiros de viagens</p>
                                            <p style={{ lineHeight: '0.5' }}>Comida favorita: Massas.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 couple-single">
                                <div className="couple-wrap couple-wrap-3">
                                    <div className="couple-img couple-img-2">
                                        <img src={'https://marryme-now.s3.amazonaws.com/6232b7e0-8ef3-489d-b48f-12eb79826151.jpg'} style={{objectFit: 'cover'}} alt="thumb"/>
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Italo Barboza</h3>
                                            <p style={{ lineHeight: '0.5' }}>28 anos</p>
                                            <p style={{ lineHeight: '0.5' }}>Profissão: Engenheiro de Software</p> 
                                            <p style={{ lineHeight: '0.5' }}>Gênero musical favorito: Bossa Nova</p>
                                            <p style={{ lineHeight: '0.5' }}>Hobby: Leitura e Kitesurf</p>
                                            <p style={{ lineHeight: '0.5' }}>Ama: Viajar e assistir série</p>
                                            <p style={{ lineHeight: '0.5' }}>Comida favorita: Churrasco.</p>                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
        </div>
        
    )
}

export default Couple;