import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import 'react-sticky-header/styles.css';
import MobileMenu from '../../components/MobileMenu'



import './style.css'

const Header = () => {

    return(

          <div className="Header_root">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="logo">
                                <h2><Link to='/home'>Luiza & Italo</Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobail-menu d-flex">
                                <li><AnchorLink href='#home'>Início</AnchorLink></li>
                                <li><a href="https://app.listaperfeita.com" rel="noopener noreferrer" target='_blank'>Lista de Presentes</a></li>
                                        {/* <ul className="submenu">
                                            <li><Link to='/home'>Home Style 1</Link></li>
                                            <li><Link to='/home2'>Home Style 2</Link></li>
                                            <li><Link to='/home3'>Home Style 3</Link></li>
                                            <li><Link to='/home4'>Home Style 4</Link></li>
                                            <li><Link to='/home5'>Home video</Link></li>
                                        </ul> */}
                                    <li><AnchorLink href='#couple'>Casal</AnchorLink></li>
                                    <li><AnchorLink href='#event'>Cerimônia</AnchorLink></li>
                                    <li><AnchorLink href='#rsvp'>Confirme Presença</AnchorLink></li>
                                    <li><AnchorLink href='#trajes'>Trajes</AnchorLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <MobileMenu/>
                        </div>
                    </div>
                </div>
             </div>
          </div>
        
    )
}

export default Header;
