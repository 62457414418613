import React from  'react';
import gta1 from '../../images/gta/img-1.jpg'
import gta2 from '../../images/gta/img-2.jpg'
import Sectiontitle from '../section-title'
import './style.css'



const Getting = () => {
    return(
        <div className="gta-area section-padding" id="trajes">
            <Sectiontitle section={'Trajes'}/>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="heading col-md-12 col-sm-6">
                                <div className="gta-content">
                                    <p>Pedimos gentilmente que todos os nossos queridos convidados vistam <b>traje social </b> 
                                        para a cerimônia e recepção. Ao selecionar seu traje para 
                                        o casamento, pedimos que <b>evitem o branco e cores similares.</b> Como é tradição, o branco é reservado 
                                        para a noiva. Acima de tudo, queremos que vocês sintam-se à vontade e confiantes em sua escolha 
                                        de vestimenta. O mais importante é celebrar este dia conosco e compartilhar nossa felicidade 
                                        enquanto nos unimos em amor e compromisso.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Getting;