
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.css'

const Welcome = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (

    <div  className={`welcome-area ${props.welcome}`}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="welcome-content">
                        <h2>Bem-vindo ao nosso grande dia</h2>
                        <p>Seja bem vindo. Aqui você pode acessar nossa lista de presentes, confirmar sua presença e visualizar
                             a localização do envento</p>
                        <div className="btn"><a href="https://app.listaperfeita.com" target='_blank' rel="noopener noreferrer">Lista de Presentes</a></div>
                        <div className="btn"><AnchorLink href='#rsvp'>Confirme Presença</AnchorLink></div>
                        <Button className="btn" onClick={toggle}>Localização</Button>
                        <Modal isOpen={modal} toggle={toggle} className={className}>
                            <ModalHeader toggle={toggle}>Localização</ModalHeader>
                            <ModalBody>
                                <div className="location-map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1990.4687200511607!2d-38.549975873688105!3d-3.823575475317593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74f653a2a01bd%3A0x9c2736099ce766ec!2sCasa%20Montenegro!5e0!3m2!1spt-BR!2sbr!4v1712869420962!5m2!1spt-BR!2sbr"  />
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
}

export default Welcome;